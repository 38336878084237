"use client"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { type Session } from "next-auth"
import React, { useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { SnackbarProvider } from "shared-ui"

import NotificationProviders from "@/components/notification-provider"
import SessionProvider from "@/components/session-provider"
// import FirebaseProvider from "@/components/firebase-provider"

import REACT_QUERY_BASE_CONFIG from "@/utils/reactQueryBaseConfig"
import OldSnackbarProvider from "@/utils/useSnackbar"

interface IClientProvidersProps {
  children: React.ReactNode
  session: Session | null
}

export default function ClientProviders(props: IClientProvidersProps) {
  const [queryClient] = useState(() => new QueryClient(REACT_QUERY_BASE_CONFIG))

  return (
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        {/* <FirebaseProvider> */}
        <SnackbarProvider>
          <SessionProvider session={props.session}>
            <OldSnackbarProvider>
              <NotificationProviders session={props.session}>
                {props.children}
              </NotificationProviders>
            </OldSnackbarProvider>
          </SessionProvider>
        </SnackbarProvider>
        {/* </FirebaseProvider> */}
      </DndProvider>
    </QueryClientProvider>
  )
}
